<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true}"></v-header>
        </div>
            <div class="content-box">
                <div class="item" v-for="(item,index) in list" :key="index">
                    <div class="id">
                        <span>{{item.left}}</span>
                    </div>
                    <div class="rightBtn" ref="rightBtn">
                        <span>{{item.rightText}}</span>
                        <img src="../../assets/images/right_arrow.png" alt="">
                    </div>
                </div>
            </div>
        <div class="submitBtn">
            <button>确认信息，并提交审核</button>
        </div>
        <div class="desc">
            <p>《重要》：</p>
            <p>1、身份证号、身份证照片、支付宝、微信号必须是本人账号</p>
            <p>2、提交成功后，平台将于每周四审核您的资料，若遇节假日顺延</p>
            <p>3、审核通过后，以后无须再次审核</p>
            <p>4、若您更换手机，请完善个人信息，如微信、QQ、便于联系</p>
            <p>5、每人一个账户每人只能绑定自己的身份信息，若有小号或者使用他人信息实名认证，将全部封号处理，不可兑换</p>
            <p>6、若有疑问，请联系客服</p>
            <p>7、上班时间：早上9:00~12:00 下午14:30~18:30</p>
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
    data(){
        return{
            title:this.$route.meta.title,
            list:[
                {
                    left:'身份证号',
                    rightText:'请填写'
                },
                {
                    left:'身份证照片',
                    rightText:'未上传'
                },
                {
                    left:'支付宝账号',
                    rightText:''
                },
                {
                    left:'微信号',
                    rightText:''
                }
            ]
        }
    },
    mounted(){
        //判断网页是什么打开
        let isWeiXin = isWeixin()
        if(isWeiXin){
        this.showHeader = false
        }
        if(this.showHeader==false){
        document.title = ''
        }
        
        let btns = this.$refs.rightBtn;
        btns.forEach((el,idx) => {
            el.addEventListener('click',()=>{
                if(idx == 0){
                    this.goPage('id')
                }else if(idx == 1){
                    this.goPage('idcard')
                }else if(idx == 2){
                    this.goPage('alipay')
                }else if(idx == 3){
                    this.goPage('weixin')
                }
            })
        });
    },
    components:{
        vHeader
    }
}
</script>
<style lang="less" scoped>
    .container{
        position: absolute;
        top: 0;
        width: 100%;
        min-height: 100%;
        background-color: #eee;
        .content-box{
            width: 100%;
            border-bottom: 1px solid #ddd;
            .item{
            width: 92%;
            margin: 0 auto; 
            height: 40px;

            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
                .id{
                }
                .rightBtn{
                    width: 58px;
                    height: 30px;
                    text-align: right;
                    img{
                        width: 10px;
                        height: 10px;
                        margin-left: 6px;
                        vertical-align: middle;
                    }
                    span{
                    vertical-align: middle;
                    color: #555;
                    }
                }
            }
        }
        .submitBtn{
            width: 100%;
            text-align: center;
            margin-top: 20px;
            button{
                border: none;
                width: 60%;
                height: 36px;
                background-color: #d25555;
                border-radius: 6px;
                font-size: 14px;
                color: #fff;
                box-shadow: 0px 2px 2px 0px rgba(210, 85, 85,0.5);
            }
        }
        .desc{
            margin-top: 40px;
            p{
                padding: 2px;
                margin: 0;
                font-size: 12px;
                color: #555;
            }
        }
    }
</style>